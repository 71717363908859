import Navigo from 'navigo';

// getElementById wrapper
function getId(id) {
    return document.getElementById(id);
}
// asyncrhonously fetch the html template partial from the file directory, then set its contents to the html of the parent element
function loadHTML(url, id) {
    // alert(url+' / '+id);
    let req = new XMLHttpRequest();
    req.open('GET', url);
    req.send();
    req.onload = () => {
        getId(id).innerHTML = req.responseText;
    };
}
function setPageAfterload (link) {
    // Add active state to sidbar nav links
    $("#layoutSidenav_nav .sidenav a.nav-link").not('[data-toggle="modal"]').each(function() {
        $(this).removeClass("active");
        if (this.href.search(link) !== -1) {
            $(this).addClass("active");
            $(this).closest('.collapse').addClass('show').prev('.nav-link-collapser').removeClass('collapsed').addClass('active');
        }
    });
    if(link=='index.html') $('.nav-link-def-route').addClass('active').closest('.collapse').addClass('show').prev('.nav-link-collapser').removeClass('collapsed').addClass('active');
    // Now doing things like binding UI Actcions for this page...
    // Activate Feather icons
    feather.replace();
    // Display .adminOnly, .accountantOnly... Objects
    switch ($.localStorage.getItem('type')) {
        case 'MyBelovedLord':
            $(".adminOnly").show();
            $(".adminHide").hide();
        break;
        default:
            $(".adminOnly").hide();
    }
    $.sessionStorage.setItem('myPage', link); // Change current page to session
    // Smooth Scroll to div...
    App.refreshSmoothScroll();
    // Add the following code if you want the name of the file appear on select
    $(".custom-file-input").on("change", function(e) {
        //const fileName = $(this).val().split("\\").pop();
        let fileName = "";
        //e.target.files.forEach(function(item){ fileName += '"'+item.name+'"';});
        //alert(e.target.files[0].name);
        for (var i = 0; i < e.target.files.length; i++) {
            fileName += '"'+e.target.files[i].name+'" ';
        }
        $(this).siblings(".custom-file-label").addClass("selected").html(fileName);
    });
}
function destroyDataTables() {
    // Make sure previous DataTables are Destroyed => fixedHeader bug fix !
    $('.dataTable').DataTable().destroy(true);
}

// Now the router... Depending on login
if($.localStorage.getItem('pass')=='OK' && document.URL.indexOf( 'login.html' ) === -1) {
    const myDomain = window.location.origin; // https://my.domain
    const rootUrl = (myDomain.indexOf('localhost')!==-1) ? '/' : '/bo/app';
    const router = new Navigo(rootUrl); // params are: root, useHash (Defaults to: false) and hash (Defaults to: '#')
    // use #! to hash
    // const router = new Navigo(null, true, '#!'); // params are: root, useHash (Defaults to: false) and hash (Defaults to: '#')
    router.hooks({
        // Ensure new data-navigo links work.
        after: function() {
            router.updatePageLinks();
        },
    });
    const routerContainer = document.getElementById('routerContainer');
    const routerContainerExist = (routerContainer) ? true : false;
    if(!routerContainerExist) document.location.href = './';
    // Direct To Cotisations...
    const goTo = App.urlParam('goto', document.URL); // Where are we going
    if(goTo == 'pay') router.navigate("cotisations");

    router.on({
        // 'routerContainer' is the id of the div element inside which we render the HTML
        '/': () => {
            destroyDataTables();
            fetch('./dash.html').then(function (response) {
                return response.text(); // To fetch html below we fetch json as we usually do...
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.dashActions();
                setPageAfterload('index.html');
                // router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/home': () => {
            destroyDataTables();
            fetch('./dash.html').then(function (response) {
                return response.text(); // To fetch html below we fetch json as we usually do...
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.dashActions();
                setPageAfterload('home');
                // router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/analytics_ca_laboratoires': () => {
            destroyDataTables();
            fetch('./analytics_ca_laboratoires.html').then(function (response) {
                return response.text(); // To fetch html below we fetch json as we usually do...
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setAnalyticsCaLabsPage();
                setPageAfterload('analytics_ca_laboratoires');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/analytics_dons': () => {
            destroyDataTables();
            fetch('./analytics_dons.html').then(function (response) {
                return response.text(); // To fetch html below we fetch json as we usually do...
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setAnalyticsDonsPage();
                setPageAfterload('analytics_dons');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/analytics_recetttes': () => {
            destroyDataTables();
            fetch('./analytics_recetttes.html').then(function (response) {
                return response.text(); // To fetch html below we fetch json as we usually do...
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setAnalyticsRecettesPage();
                setPageAfterload('analytics_recetttes');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/analytics_solde_affaires': () => {
            destroyDataTables();
            fetch('./analytics_solde_affaires.html').then(function (response) {
                return response.text(); // To fetch html below we fetch json as we usually do...
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setAnalyticsSoldeAffairesPage();
                setPageAfterload('analytics_solde_affaires');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/analytics_depenses': () => {
            destroyDataTables();
            fetch('./analytics_depenses.html').then(function (response) {
                return response.text(); // To fetch html below we fetch json as we usually do...
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setAnalyticsDepensesPage();
                setPageAfterload('analytics_depenses');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/bordereaux': () => {
            destroyDataTables();
            fetch('./page_bordereaux.html').then(function (response) {
                return response.text(); // To fetch html below we fetch json as we usually do...
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setBordereauxPage();
                setPageAfterload('bordereaux');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/contrats': () => {
            destroyDataTables();
            fetch('./page_contrats.html').then(function (response) {
                return response.text(); // To fetch html below we fetch json as we usually do...
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setContractsPage();
                setPageAfterload('contrats');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/dons': () => {
            destroyDataTables();
            fetch('./page_dons.html').then(function (response) {
                return response.text(); // To fetch html below we fetch json as we usually do...
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setGiftsPage();
                setPageAfterload('dons');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/audits': () => {
            destroyDataTables();
            fetch('./page_audits.html').then(function (response) {
                return response.text(); // To fetch html below we fetch json as we usually do...
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setAuditsPage();
                setPageAfterload('audits');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/evenements': () => {
            destroyDataTables();
            fetch('./page_evenements.html').then(function (response) {
                return response.text(); // To fetch html below we fetch json as we usually do...
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setEventsPage();
                setPageAfterload('evenements');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/stands': () => {
            destroyDataTables();
            fetch('./page_stands.html').then(function (response) {
                return response.text(); // To fetch html below we fetch json as we usually do...
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setStandsPage();
                setPageAfterload('stands');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/fichiers': () => {
            destroyDataTables();
            fetch('./page_fichiers.html').then(function (response) {
                return response.text(); // To fetch html below we fetch json as we usually do...
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setFilesListPage();
                setPageAfterload('fichiers');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/pv-ag': () => {
            destroyDataTables();
            fetch('./page_pv-ag.html').then(function (response) {
                return response.text(); // To fetch html below we fetch json as we usually do...
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.getPvAg();
                setPageAfterload('pv-ag');
                // router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/cotisations': () => {
            destroyDataTables();
            fetch('./page_cotisations.html').then(function (response) {
                return response.text(); // To fetch html below we fetch json as we usually do...
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setCotisationsPage();
                setPageAfterload('cotisations');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/paiements': () => {
            destroyDataTables();
            fetch('./page_payment_list.html').then(function (response) {
                return response.text(); // To fetch html below we fetch json as we usually do...
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setPaymentListPage();
                setPageAfterload('paiements');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/congres': () => {
            destroyDataTables();
            fetch('./page_congres.html').then(function (response) {
                return response.text(); // To fetch html below we fetch json as we usually do...
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setCongresListPage();
                setPageAfterload('congres');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/profil': () => {
            destroyDataTables();
            fetch('./page_profil.html').then(function (response) {
                return response.text(); // To fetch html below we fetch json as we usually do...
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setProfilePage();
                setPageAfterload('profil');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/utilisateurs': () => {
            destroyDataTables();
            fetch('./page_utilisateurs.html').then(function (response) {
                return response.text(); // To fetch html below we fetch json as we usually do...
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setUsersListPage();
                setPageAfterload('utilisateurs');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/deconnexion': () => {
            App.logMeOut();
            loadHTML('./logout.html', 'routerContainer');
        },
    });

    router.on(() => {
        fetch('./dash.html').then(function (response) {
            return response.text(); // To fetch html below we fetch json as we usually do...
        }).then(function (html) {
            routerContainer.innerHTML = html;
            App.dashActions();
            setPageAfterload('index.html');
            // router.updatePageLinks(); // In case there are data-navigo links on the loaded page
        }).catch(function (err) {
            console.warn('Something went wrong.', err);
        });
    });

    // set the 404 route
    router.notFound((query) => {
        loadHTML('./404.html', 'routerContainer');
        console.warn('Route not found');
        console.warn(query);
    });

    router.resolve();
}
/*
fetch('http://example.org').then(function(response) {
    if (response.ok) {
        return response.json()
    } else {
        throw ("Error " + response.status);
    }
}).then(function(data) {
    // do what you have to do with data
}).catch(function(err) {
    console.log(err);
});
*/