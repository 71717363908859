// Import CSS
import '../css/gijgo.min.css';
// import '../css/theme.default.min.css';
// import '../css/theme.dark.min.css';
// import '../css/theme.bootstrap_4.min.css';
// import './addons/pager/jquery.tablesorter.pager.css';
// import '../css/lightbox.rotate.img.css';
// import '../css/datatables.min.css';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.min.css';
import 'dropzone/dist/dropzone.css';
import '../css/styles.css';
// Import JS
import 'bootstrap';
// import './bootstrap.bundle.min.js';
import './fonts.js';
// import './font-awesome-5.11.2-all.min.js';
import Chart from 'chart.js';
import './jquery.html5storage.min.js';
// import './datatables.min.js';
// import '../demo/chart-area-demo.js';
// import '../demo/chart-bar-demo.js';
// import '../demo/chart-pie-demo.js';
// import '../demo/datatables-demo.js';
import './theme.js';
import datatablesFrench from './datatables_fr_FR.json';
// import jszip from 'jszip';
window.JSZip = require('./jszip.min.js');
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import 'datatables.net';
import 'datatables.net-bs4';
// import 'datatables.net-autofill-bs4';
import 'datatables.net-buttons/js/dataTables.buttons.js';
import 'datatables.net-buttons-bs4';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import 'datatables.net-colreorder-bs4';
// import 'datatables.net-fixedcolumns-bs4';
import 'datatables.net-fixedheader-bs4';
// import 'datatables.net-keytable-bs4';
// import 'datatables.net-responsive-bs4';
// import 'datatables.net-rowgroup-bs4';
// import 'datatables.net-rowreorder-bs4';
// import 'datatables.net-scroller-bs4';
import 'datatables.net-searchbuilder-bs4';
// import 'datatables.net-searchpanes-bs4';
// import './jquery.easing.min.js';
import './gijgo.fr.min.js';
// import './jquery.tablesorter.min.js';
// import './jquery.tablesorter.widgets.min.js';
// import './widget-cssStickyHeaders.min.js';
// import './addons/pager/jquery.tablesorter.pager.min.js';
// import './lightbox.rotate.img.js';
// import './smooth.scroll.anchor.js';
// import './FileSaver.min.js';
// import 'tableexport';
import 'dropzone';

// Set new default font family and font color to mimic Bootstrap's default styling
Chart.defaults.global.defaultFontFamily = 'Metropolis, -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif';
Chart.defaults.global.defaultFontColor = "#858796";

var globals,
App = {

	settings: {
		defLatLng: [48.86, 2.33],
		defZoom: 6,
		bottomScrolled: false,
		getFilter: false,
		myEvent: 'loadEvent',
		myPage: '',
		isMobile: false,
		// WARNING DON'T PUT THE WWW.resosign.fr AS IT LEADS TO AN OTHER SERVER !!
		serverAddress: "https://www.aderem.fr/bo/db.php",
		today: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
		myLineChart: false,
		datatablesFrench: '',
		pass: $.localStorage.getItem('pass'),
		login: $.localStorage.getItem('login'),
		pwd: $.localStorage.getItem('pwd'),
		name: $.localStorage.getItem('name'),
		firstname: $.localStorage.getItem('firstname'),
		phone: $.localStorage.getItem('phone'),
		id: $.localStorage.getItem('id'),
		email: $.localStorage.getItem('email'),
		active: $.localStorage.getItem('active'),
		type: $.localStorage.getItem('type'),
		mails: $.localStorage.getItem('mails'),
		phone2: $.localStorage.getItem('phone2'),
		address: $.localStorage.getItem('address'),
		address2: $.localStorage.getItem('address2'),
		zip: $.localStorage.getItem('zip'),
		city: $.localStorage.getItem('city'),
		country: $.localStorage.getItem('country'),
		wpid: $.localStorage.getItem('wpid'),
		contract: $.localStorage.getItem('contract'),
		quadra: $.localStorage.getItem('quadra'),
		payment: $.localStorage.getItem('payment'),
		acknowledgement: $.localStorage.getItem('acknowledgement')
	},
	refreshGlobals: function(data) {
		globals.pass = data.pass;
		globals.login = data.login;
		globals.pwd = data.pwd;
		globals.name = data.name;
		globals.firstname = data.firstname;
		globals.phone = data.phone;
		globals.id = data.id;
		globals.email = data.email;
		globals.active = data.active;
		globals.type = data.type;
		globals.mails = data.mails;
		globals.phone2 = data.phone2;
		globals.address = data.address;
		globals.address2 = data.address2;
		globals.zip = data.zip;
		globals.city = data.city;
		globals.country = data.country;
		globals.wpid = data.wpid;
		globals.contract = data.contract;
		globals.quadra = data.quadra;
		globals.payment = data.payment;
	},
	init: function() {
		// kick things off
		globals = this.settings;
		Dropzone.autoDiscover = false;
		this.bindUIActions();
		$("#now-date").append(globals.year);
		fetch(datatablesFrench).then((res) => res.json()).then((data) => { // Fetching DataTables French locales
			globals.datatablesFrench = data;
			// console.log(globals.datatablesFrench);
		});
	},
	checkAccountStatus: function () {
		$.post('https://www.aderem.fr/bo/db.php', {id: $.localStorage.getItem('id'), login: $.localStorage.getItem('login'), pwd: $.localStorage.getItem('pwd'), req: 'checkAccountStatus'}, function(data){
			if(data.check!="GO") {
				App.logMeOut();
				alert("Il y a un problème avec votre compte, veuillez réessayer plus tard\r\nSi vous pensez qu'il s'agit d'une erreur, veuillez nous contacter.");
			}
			else {
				// Every things is ok so we refresh globals and we kick things off...
				$.localStorage.setItem('login', data.login);
				$.localStorage.setItem('pwd', data.pwd);
				$.localStorage.setItem('name', data.name);
				$.localStorage.setItem('firstname', data.firstname);
				$.localStorage.setItem('phone', data.phone);
				$.localStorage.setItem('email', data.email);
				$.localStorage.setItem('id', data.id);
				$.localStorage.setItem('active', data.active);
				$.localStorage.setItem('type', data.type);
				$.localStorage.setItem('mails', data.mails);
				$.localStorage.setItem('phone2', data.phone2);
				$.localStorage.setItem('address', data.address);
				$.localStorage.setItem('address2', data.address2);
				$.localStorage.setItem('zip', data.zip);
				$.localStorage.setItem('city', data.city);
				$.localStorage.setItem('country', data.country);
				$.localStorage.setItem('wpid', data.wpid);
				$.localStorage.setItem('contract', data.contract);
				$.localStorage.setItem('quadra', data.quadra);
				$.localStorage.setItem('payment', data.payment);
				setTimeout(function(){
					App.refreshGlobals(data);
				}, 100);
				setTimeout(function(){
					App.kickThingsOffOnceLoggedIn();
				}, 200);
			}
		}, "json").fail(function(){
			App.logMeOut();
			alert("Suite à un problème technique nous ne pouvons vérifier votre compte, veuillez réessayer plus tard\r\nSi l'erreur persiste, veuillez nous contacter.");
		});
	},
	search: function (myFormDiv) {
		$(myFormDiv+' #sender').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse"></i>&nbsp;Veuillez patienter');
		let query = $('#searchForm').serialize();
		const req = "searchDocs";
		query = query + "&req=" + req;
		let returns = "";
		//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
		$.post(globals.serverAddress, query, function(data){
			if(data.ok=="ok") {
				returns = data.items;
				$('#scrollToResult').trigger('click');
			}
			else
				returns = '<div class="alert alert-danger" role="alert"><b>Aucun résultats !!</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' #sender').attr("disabled", false).html('<i class="fa fa-search"></i>&nbsp;Rechercher');
			$('#successfail').empty().append(returns);
		});
	},
	logMeIn: function (myFormDiv) {
		$(myFormDiv+' #sender').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse"></i>&nbsp;Veuillez patienter');
		$(myFormDiv).closest('.row').css('opacity', 0.6);
		let query = $(myFormDiv).serialize();
		const req = "login";
		query = query + "&req=" + req;
		$.post(globals.serverAddress, query, function(data){
			if(data.pass == "OK") {
				$.localStorage.setItem('pass', data.pass);
				$.localStorage.setItem('login', data.login);
				$.localStorage.setItem('pwd', data.pwd);
				$.localStorage.setItem('name', data.name);
				$.localStorage.setItem('firstname', data.firstname);
				$.localStorage.setItem('phone', data.phone);
				$.localStorage.setItem('email', data.email);
				$.localStorage.setItem('id', data.id);
				$.localStorage.setItem('active', data.active);
				$.localStorage.setItem('type', data.type);
				$.localStorage.setItem('mails', data.mails);
				$.localStorage.setItem('phone2', data.phone2);
				$.localStorage.setItem('address', data.address);
				$.localStorage.setItem('address2', data.address2);
				$.localStorage.setItem('zip', data.zip);
				$.localStorage.setItem('city', data.city);
				$.localStorage.setItem('country', data.country);
				$.localStorage.setItem('wpid', data.wpid);
				$.localStorage.setItem('contract', data.contract);
				$.localStorage.setItem('quadra', data.quadra);
				$.localStorage.setItem('payment', data.payment);
				setTimeout(function(){
					App.refreshGlobals(data);
				}, 100);
				setTimeout(function(){
					if(data.type=='aphm') document.location.href='./aphm.html';
					else document.location.href='./';
				}, 1000);
			}
			else {
				if(data.active==0) alert("Ce compte a été désactivé !");
				else alert("Identifiant ou mot de passe erroné !");
			}
		}, "json").always(function() {
			$(myFormDiv+' #sender').attr("disabled", false).html('<b><i class="fa fa-unlock"></i> Connexion</b>');
			$(myFormDiv).closest('.row').css('opacity', 1);
		});
	},
	logMeOut: function () {
		$.localStorage.setItem('pass', false);
		$.localStorage.setItem('login', '');
		$.localStorage.setItem('pwd', '');
		$.localStorage.setItem('name', '');
		$.localStorage.setItem('firstname', '');
		$.localStorage.setItem('phone', '');
		$.localStorage.setItem('id', '');
		$.localStorage.setItem('email', '');
		$.localStorage.setItem('active', '');
		$.localStorage.setItem('type', '');
		$.localStorage.setItem('mails', '');
		$.localStorage.setItem('phone2', '');
		$.localStorage.setItem('address', '');
		$.localStorage.setItem('address2', '');
		$.localStorage.setItem('zip', '');
		$.localStorage.setItem('city', '');
		$.localStorage.setItem('country', '');
		$.localStorage.setItem('wpid', '');
		$.localStorage.setItem('contract', '');
		$.localStorage.setItem('quadra', '');
		$.localStorage.setItem('payment', '');
		setTimeout(function(){
			// document.location.href='login.html';
			document.location.href='https://www.aderem.fr';
		}, 1000);
	},
	subContact: function (myFormDiv) {
		$(myFormDiv+' #sender').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse"></i>&nbsp;Veuillez patienter');
		let query = $(myFormDiv).serialize();
		const req = "contact";
		query = query + "&req=" + req;
		let returns = "";
		//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
		$.post(globals.serverAddress, query, function(data){
			if(data.ok=="ok")
				returns = '<div class="alert alert-success" role="alert"><b>Votre message a bien été envoyé.</b></div>';
			else
				returns = '<div class="alert alert-danger" role="alert"><b>Votre message n\'a pas été envoyé.</b></div>';
			$(myFormDiv+' #sender').attr("disabled", false).html('Envoyer&nbsp;<i class="fa fa-paper-plane"></i>');
			$(myFormDiv+' #successfail').empty().append(returns);
		}, "json");
	},
	addWasValidatedClass: function (myFormDiv) {
		$(myFormDiv).addClass('was-validated');
		var wrongFields = "Les champs suivants sont mal renseignés : \n";
		var wrongPop = false;
		$(myFormDiv).find(':invalid').each(function () {
			//alert("IN");
			wrongPop = true;
			$(this).closest('.form-group').find('label').not('.notValidatedClass').each(function () {
				wrongFields += $(this).text()+"\n";
			});
		});
		if(wrongPop) alert(wrongFields);
		return !wrongPop;
	},
	clearFormFields: function(myFormDiv, event) {
		// if(myFormDiv=='#editPicturesAddressForm') event.preventDefault(); // prevents form submission when button is inside it !
		$(myFormDiv).find("input[type=text], input[type=tel], input[type=email], input[type=number], input[type=file], textarea, select").val("");
		$(myFormDiv).find("input[type=checkbox]").prop("checked", false);
		$(myFormDiv).removeClass('was-validated');
		$(myFormDiv+' #successfail').empty();
	},
	safeJsonParse: function(input) {
		try {
			return JSON.parse(input);
		} catch (e) {
			return undefined;
		}
	},
	urlParam: function(name, url){
		// Get parameters from an URL
		var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(url);
		//For current URL
		//var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
		if (results==null){
			return null;
		}
		else{
			return results[1] || 0;
		}
	},
	bindUIActions: function() {
		if(globals.pass == "OK") App.checkAccountStatus(); // Connected or not
		if(globals.acknowledgement!='ACK') $('#acknowledgementPop').modal('show'); // Pops disclaimer if not acknoledged yet.
		// Is it Mobile device
		if(/Mobi/i.test(navigator.userAgent) || /Android/i.test(navigator.userAgent) || window.innerWidth<992) globals.isMobile = true;
		// if(globals.isMobile) $('.sidebar').addClass('toggled');
		$('.expends').on("click", function () {
			$(this).next('div').slideToggle('slow');
			//$.mobile.silentScroll($(this).next('div').offset().top);
		});
		// Managing navigation so that we bindUIActions on the right page...
		let url = window.location.pathname;
		globals.myPage = url.substring(url.lastIndexOf('/')+1);
		$.sessionStorage.setItem('myPage', globals.myPage);
		if (globals.myPage != 'login.html') {
			// All pages except login
			$('.my-account-name').empty().append(globals.name+' '+globals.firstname);
			$('.my-account-mail').empty().append(globals.email);
			$('#modal_contact #from_name').val(globals.name+' '+globals.firstname);
			$('#modal_contact #from_email').val(globals.email);
			$('#modal_contact #from_phone').val(globals.phone);
		}
		// Smooth Scroll to div...
		App.refreshSmoothScroll();
		// Add the following code if you want the name of the file appear on select
		$(".custom-file-input").on("change", function(e) {
			//const fileName = $(this).val().split("\\").pop();
			let fileName = "";
			//e.target.files.forEach(function(item){ fileName += '"'+item.name+'"';});
			//alert(e.target.files[0].name);
			for (var i = 0; i < e.target.files.length; i++) {
				fileName += '"'+e.target.files[i].name+'" ';
			}
			$(this).siblings(".custom-file-label").addClass("selected").html(fileName);
		});
		$('#addBordereauxModal #date').datepicker({
			locale: 'fr-fr',
			format: 'dd/mm/yyyy',
			value: globals.today.toLocaleDateString('fr-FR'),
			minDate: globals.today,
			maxDate: globals.today,
			modal: true,
			showOtherMonths: true,
			iconsLibrary: 'fontawesome',
			uiLibrary: 'bootstrap4'
			//uiLibrary: 'bootstrap'
		});
		$('#modBordereauxModal #pay_date').datepicker({
			locale: 'fr-fr',
			format: 'dd/mm/yyyy',
			// value: globals.today.toLocaleDateString('fr-FR'),
			// minDate: globals.today,
			modal: true,
			showOtherMonths: true,
			iconsLibrary: 'fontawesome',
			uiLibrary: 'bootstrap4'
			// uiLibrary: 'bootstrap'
		});
		/*
		// On Enter search...
		$('#keywords').keydown(function(e) {
			var keywords = $('#keywords').val();
			if (e.keyCode === 13) {
				App.search();
			}
		});
		// Continuous search...
		$('#keywords').keyup(function() {
			var keywords = $('#keywords').val();
			if(keywords.length > 6) {
				App.search();
			} else {
				// doNothing !
			}
		});
		*/
	},
	kickThingsOffOnceLoggedIn: function() {
		this.buildMyDropzone();
		// Display .adminOnly, .accountantOnly... Objects
		switch (globals.type) {
			case 'MyBelovedLord':
				$(".adminOnly").show();
				$(".adminHide").hide();
			break;
			default:
				$(".adminOnly").hide();
		}
		if(this.settings.payment<1 && this.settings.type=='user') { // If User's subscription is still not payed
			this.popSomeonesPaymentPage(this.settings.id); // Pops user's payment page @load.
			const addBordereauxLink = document.getElementById('addBordereauxLink')
			addBordereauxLink.setAttribute('href', '#cotisationModal') // Pops user's payment page instaed of addBordereauxModal.
		}
		App.getNavNotifications();
		$('#addBordereauxForm #entry_by').val(globals.id);
		$('#addBordereauxForm #project').val(globals.contract);
		if(globals.type!='aphm') {
			$.post(globals.serverAddress, {id: globals.id, type: globals.type, centers: globals.contract, pwd: globals.pwd, req: 'getContractSelectBox'}, function(data){
				$('#addBordereauxForm [data-contract-options]').html(data.snippet);
				if(data.solde<=0) {
					$('#addBordereauxForm #sender').attr('disabled', true);
					$('#addBordereauxForm #successfail').html('<div class="alert alert-danger" role="alert"><b>Vous ne pouvez créer de nouveau Bordereau car votre compte présente un solde négatif.</b></div>');
				}
				// $('#addBordereauxForm #contractSelectBoxCont').empty().append(data.snippet);
			}, "json");
		}
		else {
			$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, centers: globals.contract, req: 'getContractsFilesAphm'}, function(data){
				$("#contractsListCont").empty().append(data.list);
				$("#filesListCont").empty().append(data.files);
			}, "json").done(function() {
				App.buildDataTables('#dataTable', 25, true, 3, 'desc', 'ADEREM Liste des Contrats');
				App.refreshSmoothScroll();
			});
		}
		const goTo = App.urlParam('goto', document.URL); // Where are we going
		const idBord = App.urlParam('id', document.URL); // Then get the id
		if(goTo == 1) App.fillModBordereaux(idBord, $("#modBordereauxBtn_"+idBord), '#modBordereauxForm', '#modBordereauxModal');
		/*
		if(globals.isMobile) $('#cotisationFramePop').closest('.embed-responsive').removeClass('embed-responsive-16by9').addClass('embed-responsive-1by1');
		if(goTo == 'pay') {
			// $('#cotisationFramePop').attr('src', 'https://www.aderem.fr/bo/pay/paypage.php?auto_u='+globals.id);
			if($('#cotisationFramePop').length) {
				const cotisationFramePop = document.getElementById('cotisationFramePop');
				cotisationFramePop.src += '?auto_u='+globals.id;
			}
			$('#cotisationModal').modal("show");
		}
		*/
	},
	getNavNotifications: function()
	{
		const req = "getNavNotifications";
		const query = "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&req=" + req;
		$.post(globals.serverAddress, query, function(data){
			$("#navbarDropdownAlerts").empty().append(data.badge);
			$("#navNotificationsCont").empty().append(data.snippet);
		}, "json").always(function(){
			// Refreshing alerts every now and then...
			if(globals.type=='MyBelovedLord') {
				setTimeout(function(){
					App.getNavNotifications();
				}, 60000);
			}
		});
	},
	refreshSmoothScroll: function() {
		// Smooth Scroll to div...
		$('a[href*="#"]').not('a.scroll-to-top').on('click', function (e) {
			let offset = 0;
			const target = this.hash;
			if($(this).data('offset') != undefined) offset = $(this).data('offset'); // if set data-offset="pixels"
			if($(target).length) {
				$('html, body').stop().animate({
					'scrollTop': $(target).offset().top - offset
				}, 900, 'swing', function() {
					// window.location.hash = target;
				});
			}
			else if($(this).is('.downToDocs')) {
				alert("Il n'y a aucun document associé !");
			}
		});
	},
	acknowledge: function(thisBtn) {
		$(thisBtn).attr("disabled", true).html('<i class="fa fa-spinner fa-pulse"></i>&nbsp;Veuillez patienter');
		const query = "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&req=acknowledgeGdpr";
		$.post(globals.serverAddress, query, function(data){
			if(data.ok=='ok') $.localStorage.setItem('acknowledgement', 'ACK');
			else alert("suite a un problème technique, nous n'avons pu recueillir votre consentement !");
		}, "json").always(function(){
			$(thisBtn).attr("disabled", false).html('<i class="fa fa-vote-yea"></i>&nbsp;ACCEPTER');
			$('#acknowledgementPop').modal('hide');
		});
	},
	number_format: function(number, decimals, dec_point, thousands_sep) {
		// *     example: number_format(1234.56, 2, ',', ' ');
		// *     return: '1 234,56'
		number = (number + "").replace(",", "").replace(" ", "");
		var n = !isFinite(+number) ? 0 : +number,
			prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
			sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
			dec = typeof dec_point === "undefined" ? "." : dec_point,
			s = "",
			toFixedFix = function(n, prec) {
				var k = Math.pow(10, prec);
				return "" + Math.round(n * k) / k;
			};
		// Fix for IE parseFloat(0.55).toFixed(0) = 0;
		s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
		if (s[0].length > 3) {
			s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
		}
		if ((s[1] || "").length < prec) {
			s[1] = s[1] || "";
			s[1] += new Array(prec - s[1].length + 1).join("0");
		}
		return s.join(dec);
	},
	dashActions: function() {
		App.getEcrituresList();
		App.setProjectsStats();
		/*
		// Bar Chart Example
		const myBarChart = new Chart($("#myBarChart"), {
			type: "bar",
			data: {
				labels: ["January", "February", "March", "April", "May", "June"],
				datasets: [{
					label: "Revenue",
					backgroundColor: "rgba(0, 97, 242, 1)",
					hoverBackgroundColor: "rgba(0, 97, 242, 0.9)",
					borderColor: "#4e73df",
					data: [4215, 5312, 6251, 7841, 9821, 14984]
				}]
			},
			options: {
				maintainAspectRatio: false,
				layout: {
					padding: {
						left: 10,
						right: 25,
						top: 25,
						bottom: 0
					}
				},
				scales: {
					xAxes: [{
						time: {
							unit: "month"
						},
						gridLines: {
							display: false,
							drawBorder: false
						},
						ticks: {
							maxTicksLimit: 6
						},
						maxBarThickness: 25
					}],
					yAxes: [{
						ticks: {
							min: 0,
							max: 15000,
							maxTicksLimit: 5,
							padding: 10,
							// Include a dollar sign in the ticks
							callback: function(value, index, values) {
								return App.number_format(value, 2, ',', ' ') + " €";
							}
						},
						gridLines: {
							color: "rgb(234, 236, 244)",
							zeroLineColor: "rgb(234, 236, 244)",
							drawBorder: false,
							borderDash: [2],
							zeroLineBorderDash: [2]
						}
					}]
				},
				legend: {
					display: false
				},
				tooltips: {
					titleMarginBottom: 10,
					titleFontColor: "#6e707e",
					titleFontSize: 14,
					backgroundColor: "rgb(255,255,255)",
					bodyFontColor: "#858796",
					borderColor: "#dddfeb",
					borderWidth: 1,
					xPadding: 15,
					yPadding: 15,
					displayColors: false,
					caretPadding: 10,
					callbacks: {
						label: function(tooltipItem, chart) {
							var datasetLabel =
								chart.datasets[tooltipItem.datasetIndex].label || "";
							return datasetLabel + ": " + App.number_format(tooltipItem.yLabel, 2, ',', ' ') + " €";
						}
					}
				}
			}
		});
		*/
	},
	buildDataTables: function(myDiv, pageLength = 25, colReorder = true, orderCol = 0, orderWay = 'desc', expTitle = '', filterColumsText = [], filterColumsSelect = [], myFilterField, myFilterCondition, myFilter, pdfExtraExportOptions, fixedHeader) {
		// Like : App.buildDataTables('#dataTable', 25, true, 4, 'desc', 'Titre');
		// Or : App.buildDataTables('#dataTable', 25, true, 4, 'desc', 'Titre', [0,1,2,3,4,5,6], [7,8], 'Centre', '=', myFilter, [0,1,2,3,4,5,6], true);
		fixedHeader = (typeof fixedHeader !== 'undefined') ? fixedHeader : true;
		let fixedFooter = false;
		let headerOffset = 60;
		if(globals.isMobile) fixedHeader = false;
		const dom = (globals.type=='user') ? '<"col-12 col-md-6 d-flex align-items-center"l><"col-12 col-md-6 d-flex align-items-center justify-content-end"f>rt<"col-12 col-md-6"i><"col-12 col-md-6"p>' : '<"col-12 col-md-4 d-flex align-items-center justify-content-center justify-content-md-start"l><"col-12 col-md-4 d-flex align-items-center justify-content-center"f><"col-12 col-md-4 d-flex align-items-center justify-content-center justify-content-md-end"B>rt<"col-12 col-md-6"i><"col-12 col-md-6"p>';
		// Add row class to dataTable wrapper => Usefull to correcty display buttons using the dom option => Done in css directly on .dataTables_wrapper
		// $(myDiv+'_wrapper').addClass('row');
		const pdfExtra = (typeof pdfExtraExportOptions !== 'undefined') ? pdfExtraExportOptions : '';
		const baseFiltering = (typeof myFilter !== 'undefined') ? myFilter : '';
		let myTable;
		if (baseFiltering.length>0) {
			let tabFilter = new Array(myFilter);
			myTable = $(myDiv).DataTable({
				destroy: true,
				dom: dom,
				language: globals.datatablesFrench,
				// language: {
				//	url: 'https://cdn.datatables.net/plug-ins/1.11.3/i18n/fr_fr.json'
				// },
				pageLength: pageLength,
				colReorder: colReorder,
				fixedHeader: {header: fixedHeader, footer: fixedFooter, headerOffset: headerOffset},
				order: [], // disable default sorting order wi'll apply ordering conditionaly below
				buttons: [
					{text:'<li class="fa fa-2x fa-copy"></li>', title: expTitle, extend:'copy'},
					{text:'<li class="fa fa-2x fa-file-excel"></li>', title: expTitle, extend:'excel'},
					{text:'<li class="fa fa-2x fa-file-pdf"></li>', title: expTitle, exportOptions: {stripNewlines: false, columns: pdfExtra}, orientation: 'landscape', pageSize: 'LEGAL', extend:'pdf'},
					// {text:'<li class="fa fa-2x fa-file-pdf"></li>', title: 'SNS-GPI Liste des Bordereaux', orientation: 'landscape', pageSize: 'LEGAL', exportOptions: {columns:[0,1,2,3,5,6,7]}, extend:'pdf'},
					{text:'<li class="fa fa-2x fa-print"></li>', title: expTitle, exportOptions: {stripHtml: false, stripNewlines: false}, extend:'print'}
				],
				searchBuilder: {
					preDefined: {
						criteria: [
							{
								data: myFilterField,
								condition: myFilterCondition,
								value: tabFilter
							}
						]
					}
				},
				initComplete: function () {
					if(filterColumsText.length>0 || filterColumsSelect.length>0) {
						$(myDiv+' thead tr').clone(false).addClass('filters').appendTo(myDiv+' thead');
						$(myDiv+' tr.filters .filter-false').empty().removeClass('sorting');
					}
					if(filterColumsText.length>0) {
						this.api().columns(filterColumsText).every( function () {
							let column = this;
							var cell = $(myDiv+' tr.filters th').eq($(column.header()).index()).empty().removeClass(['sorting', 'sorting_asc', 'sorting_desc']);
							let input = $('<input class="form-control form-control-sm table-filter" type="text" placeholder="Rechercher" />')
							.appendTo( $(cell) )
							// .appendTo( $(column.header()) )
							.off('keyup change')
							.on('keyup change', function (e) {
								// e.stopPropagation();
								let val = $.fn.dataTable.util.escapeRegex(
									$(this).val()
								);
								let regexr = '({search})';
								column.search(val != '' ? regexr.replace('{search}', '(((' + val + ')))') : '', val != '', val == '').draw();
							});
						});
					}
					if(filterColumsSelect.length>0) {
						this.api().columns(filterColumsSelect).every( function () {
							let column = this;
							let filterDataArray = [];
							var cell = $(myDiv+' tr.filters th').eq($(column.header()).index()).empty().removeClass(['sorting', 'sorting_asc', 'sorting_desc']);
							let select = $('<select class="form-control form-control-sm table-filter"><option value=""></option></select>')
							.appendTo( $(cell) )
							// .appendTo( $(column.header()) )
								.on( 'change', function () {
									let val = $.fn.dataTable.util.escapeRegex(
										$(this).val()
									);
									column
										.search( val ? '^'+val+'$' : '', true, false )
										.draw();
								});
							column.data().unique().sort().each( function ( d, j ) {
								let filterData = d;
								if(d.indexOf('</') !== -1) { // In case there is html tags we strip those...
									filterData = ($(d).length) ? $(d).text() : d;
									// console.warn(d+' - '+$(d));
								}
								// select.append( '<option value="'+filterData+'">'+filterData+'</option>' );
								if(filterData!='') filterDataArray.push(filterData);
							});
							// In case there was html tags we need to sort the data again
							filterDataArray.sort();
							filterDataArray.forEach(element => {
								select.append( '<option value="'+element+'">'+element+'</option>' );
							});
						});
					}
				}
			});
		}
		else {
			myTable = $(myDiv).DataTable({
				destroy: true,
				dom: dom,
				language: globals.datatablesFrench,
				// language: {
				//	url: 'https://cdn.datatables.net/plug-ins/1.11.3/i18n/fr_fr.json'
				// },
				pageLength: pageLength,
				colReorder: colReorder,
				fixedHeader: {header: fixedHeader, footer: fixedFooter, headerOffset: headerOffset},
				order: [], // disable default sorting order will apply ordering conditionaly below
				buttons: [
					{text:'<li class="fa fa-2x fa-copy"></li>', title: expTitle, extend:'copy'},
					{text:'<li class="fa fa-2x fa-file-excel"></li>', title: expTitle, extend:'excel'},
					{text:'<li class="fa fa-2x fa-file-pdf"></li>', title: expTitle, exportOptions: {stripNewlines: false, columns: pdfExtra}, orientation: 'landscape', pageSize: 'LEGAL', extend:'pdf'},
					{text:'<li class="fa fa-2x fa-print"></li>', title: expTitle, exportOptions: {stripHtml: false, stripNewlines: false}, extend:'print'}
				],
				initComplete: function () {
					if(filterColumsText.length>0 || filterColumsSelect.length>0) {
						$(myDiv+' thead tr').clone(false).addClass('filters').appendTo(myDiv+' thead');
						$(myDiv+' tr.filters .filter-false').empty().removeClass('sorting');
					}
					if(filterColumsText.length>0) {
						this.api().columns(filterColumsText).every( function () {
							let column = this;
							var cell = $(myDiv+' tr.filters th').eq($(column.header()).index()).empty().removeClass(['sorting', 'sorting_asc', 'sorting_desc']);
							let input = $('<input class="form-control form-control-sm table-filter" type="text" placeholder="Rechercher" />')
							.appendTo( $(cell) )
							// .appendTo( $(column.header()) )
							.off('keyup change')
							.on('keyup change', function (e) {
								// e.stopPropagation();
								let val = $.fn.dataTable.util.escapeRegex(
									$(this).val()
								);
								let regexr = '({search})';
								column.search(val != '' ? regexr.replace('{search}', '(((' + val + ')))') : '', val != '', val == '').draw();
							});
						});
					}
					if(filterColumsSelect.length>0) {
						this.api().columns(filterColumsSelect).every( function () {
							let column = this;
							let filterDataArray = [];
							var cell = $(myDiv+' tr.filters th').eq($(column.header()).index()).empty().removeClass(['sorting', 'sorting_asc', 'sorting_desc']);
							let select = $('<select class="form-control form-control-sm table-filter"><option value=""></option></select>')
							.appendTo( $(cell) )
							// .appendTo( $(column.header()) )
								.on( 'change', function () {
									let val = $.fn.dataTable.util.escapeRegex(
										$(this).val()
									);
									column
										.search( val ? '^'+val+'$' : '', true, false )
										.draw();
								});
							column.data().unique().sort().each( function ( d, j ) {
								let filterData = d;
								if(d.indexOf('</') !== -1) { // In case there is html tags we strip those...
									filterData = ($(d).length) ? $(d).text() : d;
									// console.warn(d+' - '+$(d));
								}
								// select.append( '<option value="'+filterData+'">'+filterData+'</option>' );
								if(filterData!='') filterDataArray.push(filterData);
							});
							// In case there was html tags we need to sort the data again
							filterDataArray.sort();
							filterDataArray.forEach(element => {
								select.append( '<option value="'+element+'">'+element+'</option>' );
							});
						});
					}
				}
			});
		}
		if(orderWay) myTable.order([ orderCol, orderWay ]).draw(); // Conditionaly ordering now
		// myTable.fixedHeader.adjust();
		// if(globals.isMobile) myTable.fixedHeader.disable();
	},
	getEcrituresList: function(myFilter, year) {
		const contracts = globals.contract.replace(/\s/g, '').split(","); // strip spaces then make an array
		let isLikeAd = globals.type;
		isLikeAd = (contracts.length>10) ? 'MyBelovedLord' : isLikeAd;
		$("#ecrituresListCont").html('<div class="loading text-center"><h1 class="text-dark">Chargement...</h1><i class="fa fa-10x fa-spinner fa-pulse"></i></div>');
		$.post(globals.serverAddress, {id: globals.id, type: isLikeAd, pwd: globals.pwd, centers: globals.contract, year: year, req: 'getEcrituresList'}, function(data){
			$("#ecrituresListCont").empty().append(data.snippet);
			$("#filtersContAdmin").empty().append(data.filters);
			$("#projectBalanceCont").empty().append(data.projectBalance);
			$("#projectDebitCont").empty().append(data.projectDebit);
			$("#projectCreditCont").empty().append(data.projectCredit);
		}, "json").done(function() {
			if(!myFilter) App.buildDataTables('#dataTable', 25, true, 6, 'desc', 'ADEREM Liste des Ecritures', [5,6,7,8], [0,1,2,3,4]);
			else App.buildDataTables('#dataTable', 25, true, 6, 'desc', 'ADEREM Liste des Ecritures', [5,6,7,8], [0,1,2,3,4], 'Centre', '=', myFilter);
		});
		// Creating Contracts Filters...
		if(globals.type=='user' && contracts.length<=10) {
			let filterBtns = '<b>Mes Budgets : </b>';
			contracts.forEach(function(item, index){
				filterBtns += '<button class="btn btn-primary btn-lg shadow" onclick="App.filterEcrituresList(\''+item+'\');" title="Budget '+item+'"><i class="fa fa-filter"></i>&nbsp;'+item+'</button>&nbsp;';
			});
			filterBtns += '<button class="btn btn-warning btn-icon shadow" onclick="App.dashActions();" title="Rafraîchir / Effacer le filtre"><i class="fa fa-2x fa-sync"></i></button>';
			$("#filtersCont").empty().append(filterBtns);
		}
	},
	filterEcrituresList: function(myFilter) {
		/*
		$('#loadingModal').modal('show');
		setTimeout(function() {
			$('#loadingModal').modal('hide');
		}, 1600);
		*/
		App.filterProjectsStats(myFilter);
		App.getEcrituresList(myFilter);
		// App.buildDataTables('#dataTable', 25, true, 4, 'desc', 'ADEREM Liste des Ecritures', [0,3,4,5,6], [1,2], 'Centre', '=', myFilter);
	},
	setProjectsStats: function() {
		if(globals.myLineChart) globals.myLineChart.destroy();
		document.querySelector('#myAreaChartLoader').classList.remove('d-none');
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, centers: globals.contract, req: 'getDebitCreditLineStats'}, function(data){
			document.querySelector('#myAreaChartLoader').classList.add('d-none');
			globals.myLineChart = new Chart($("#myAreaChart"), {
				type: "line",
				data: {
					labels: data.lineLabels,
					datasets: [{
						label: "Débit",
						lineTension: 0.3,
						backgroundColor: "rgba(105, 0, 199, 0.05)",
						borderColor: "rgba(105, 0, 199, 1)",
						pointRadius: 3,
						pointBackgroundColor: "rgba(105, 0, 199, 1)",
						pointBorderColor: "rgba(105, 0, 199, 1)",
						pointHoverRadius: 3,
						pointHoverBackgroundColor: "rgba(105, 0, 199, 1)",
						pointHoverBorderColor: "rgba(105, 0, 199, 1)",
						pointHitRadius: 10,
						pointBorderWidth: 2,
						data: data.lineDataDebits
					}, {
						label: "Crédit",
						lineTension: 0.3,
						backgroundColor: "rgba(255, 193, 71, 0.05)",
						borderColor: "rgba(255, 193, 71, 1)",
						pointRadius: 3,
						pointBackgroundColor: "rgba(255, 193, 71, 1)",
						pointBorderColor: "rgba(255, 193, 71, 1)",
						pointHoverRadius: 3,
						pointHoverBackgroundColor: "rgba(255, 193, 71, 1)",
						pointHoverBorderColor: "rgba(255, 193, 71, 1)",
						pointHitRadius: 10,
						pointBorderWidth: 2,
						data: data.lineDataCredits
					}]
				},
				options: {
					'responsive': true,
					'maintainAspectRatio':false,
					'legend':{'display':true, position: 'bottom'},
					layout: {
						padding: {
							left: 10,
							right: 25,
							top: 25,
							bottom: 0
						},
						height: 360,
					},
					scales: {
						xAxes: [{
							time: {
								unit: "date"
							},
							gridLines: {
								display: false,
								drawBorder: false
							},
							ticks: {
								maxTicksLimit: 7
							}
						}],
						yAxes: [{
							ticks: {
								maxTicksLimit: 5,
								padding: 10,
								// Include a dollar sign in the ticks
								callback: function(value, index, values) {
									return App.number_format(value, 2, ',', ' ') + " €";
								}
							},
							gridLines: {
								color: "rgb(234, 236, 244)",
								zeroLineColor: "rgb(234, 236, 244)",
								drawBorder: false,
								borderDash: [2],
								zeroLineBorderDash: [2]
							}
						}]
					},
					legend: {
						display: false
					},
					tooltips: {
						backgroundColor: "rgb(255,255,255)",
						bodyFontColor: "#858796",
						titleMarginBottom: 10,
						titleFontColor: "#6e707e",
						titleFontSize: 14,
						borderColor: "#dddfeb",
						borderWidth: 1,
						xPadding: 15,
						yPadding: 15,
						displayColors: false,
						intersect: false,
						mode: "index",
						caretPadding: 10,
						callbacks: {
							label: function(tooltipItem, chart) {
								var datasetLabel =
									chart.datasets[tooltipItem.datasetIndex].label || "";
								return datasetLabel + ": " + App.number_format(tooltipItem.yLabel, 2, ',', ' ') + " €";
							}
						}
					}
				}
			});
		}, "json");
	},
	filterProjectsStats: function(myFilter) {
		globals.myLineChart.destroy();
		document.querySelector('#myAreaChartLoader').classList.remove('d-none');
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, centers: myFilter, req: 'filterProjectsStats'}, function(data){
			$("#projectBalanceCont").empty().append(data.projectBalance);
			$("#projectDebitCont").empty().append(data.projectDebit);
			$("#projectCreditCont").empty().append(data.projectCredit);
			document.querySelector('#myAreaChartLoader').classList.add('d-none');
			globals.myLineChart = new Chart($("#myAreaChart"), {
				type: "line",
				data: {
					labels: data.lineLabels,
					datasets: [{
						label: "Débit",
						lineTension: 0.3,
						backgroundColor: "rgba(105, 0, 199, 0.05)",
						borderColor: "rgba(105, 0, 199, 1)",
						pointRadius: 3,
						pointBackgroundColor: "rgba(105, 0, 199, 1)",
						pointBorderColor: "rgba(105, 0, 199, 1)",
						pointHoverRadius: 3,
						pointHoverBackgroundColor: "rgba(105, 0, 199, 1)",
						pointHoverBorderColor: "rgba(105, 0, 199, 1)",
						pointHitRadius: 10,
						pointBorderWidth: 2,
						data: data.lineDataDebits
					}, {
						label: "Crédit",
						lineTension: 0.3,
						backgroundColor: "rgba(255, 193, 71, 0.05)",
						borderColor: "rgba(255, 193, 71, 1)",
						pointRadius: 3,
						pointBackgroundColor: "rgba(255, 193, 71, 1)",
						pointBorderColor: "rgba(255, 193, 71, 1)",
						pointHoverRadius: 3,
						pointHoverBackgroundColor: "rgba(255, 193, 71, 1)",
						pointHoverBorderColor: "rgba(255, 193, 71, 1)",
						pointHitRadius: 10,
						pointBorderWidth: 2,
						data: data.lineDataCredits
					}]
				},
				options: {
					'responsive': true,
					'maintainAspectRatio':false,
					'legend':{'display':true, position: 'bottom'},
					layout: {
						padding: {
							left: 10,
							right: 25,
							top: 25,
							bottom: 0
						},
						height: 360,
					},
					scales: {
						xAxes: [{
							time: {
								unit: "date"
							},
							gridLines: {
								display: false,
								drawBorder: false
							},
							ticks: {
								maxTicksLimit: 7
							}
						}],
						yAxes: [{
							ticks: {
								maxTicksLimit: 5,
								padding: 10,
								// Include a dollar sign in the ticks
								callback: function(value, index, values) {
									return App.number_format(value, 2, ',', ' ') + " €";
								}
							},
							gridLines: {
								color: "rgb(234, 236, 244)",
								zeroLineColor: "rgb(234, 236, 244)",
								drawBorder: false,
								borderDash: [2],
								zeroLineBorderDash: [2]
							}
						}]
					},
					legend: {
						display: false
					},
					tooltips: {
						backgroundColor: "rgb(255,255,255)",
						bodyFontColor: "#858796",
						titleMarginBottom: 10,
						titleFontColor: "#6e707e",
						titleFontSize: 14,
						borderColor: "#dddfeb",
						borderWidth: 1,
						xPadding: 15,
						yPadding: 15,
						displayColors: false,
						intersect: false,
						mode: "index",
						caretPadding: 10,
						callbacks: {
							label: function(tooltipItem, chart) {
								var datasetLabel =
									chart.datasets[tooltipItem.datasetIndex].label || "";
								return datasetLabel + ": " + App.number_format(tooltipItem.yLabel, 2, ',', ' ') + " €";
							}
						}
					}
				}
			});
		}, "json");
	},
	setBordereauxPage: function() {
		App.getBordereauxList();
		App.getBordereauxBadges();
		/*
		$('#modBordereauxModal #date').datepicker({
			locale: 'fr-fr',
			format: 'dd/mm/yyyy',
			minDate: globals.today,
			modal: true,
			showOtherMonths: true,
			iconsLibrary: 'fontawesome',
			uiLibrary: 'bootstrap4'
			//uiLibrary: 'bootstrap'
		});
		*/
	},
	getBordereauxList: function(myFilter) {
		$("#bordereauxListCont").html('<div class="loading text-center"><h1 class="text-dark">Chargement...</h1><i class="fa fa-10x fa-spinner fa-pulse"></i></div>');
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, req: 'getBordereauxList'}, function(data){
			$("#bordereauxListCont").empty().append(data.snippet);
		}, "json").done(function() {
			if(!myFilter) App.buildDataTables('#dataTable', 25, true, 1, 'desc', 'ADEREM Liste des Bordereaux', [0,1,2,3,4,5,6,7], [8], '', '', '', [0,1,2,3,5,6,7,8]);
			else App.buildDataTables('#dataTable', 25, true, 0, 'desc', 'ADEREM Liste des Bordereaux', [0,1,2,3,4,5,6,7], [8], 'Statut', '=', myFilter, [0,1,2,3,5,6,7]);
			const goTo = App.urlParam('goto', document.URL); // Where are we going
			const idBord = App.urlParam('id', document.URL); // Then get the id
			// if(goTo == 1) $("#modBordereauxBtn_"+idBord).trigger("click");
			if(goTo == 1) App.fillModBordereaux(idBord, $("#modBordereauxBtn_"+idBord), '#modBordereauxForm', '#modBordereauxModal');
		});
	},
	filterBordereauxList: function(myFilter) {
		this.getBordereauxList(myFilter);
		// App.buildDataTables('#dataTable', 25, true, 0, 'desc', 'ADEREM Liste des Bordereaux', [0,1,2,3,4,5,6,7], [8], 'Statut', '=', myFilter, [0,1,2,3,5,6,7]);
	},
	getBordereauxBadges: () => {
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, req: 'getBordereauxBadges'}, function(data){
			$("#bordereauxParAnCont").html(data.bordereauxParAn);
			$("#bordereauxMontantsMoyensCont").html(data.bordereauxMontantsMoyens);
			$("#bordereauxValidesCont").html(data.bordereauxValides);
			$("#bordereauxProblemeCont").html(data.bordereauxProbleme);
			$("#bordereauxRefusCont").html(data.bordereauxRefus);
		}, "json");
	},
	fillModBordereaux: function(id_b, thisBtn, myFormDiv, myFormModal) {
		App.clearFormFields(myFormDiv);
		const req = 'fillModBordereaux';
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, id_b: id_b, req: req}, function(data){
			//echo json_encode(array("ok"=>$ok, "snippet"=>$snippet, "description"=>$description, "date"=>$date, "fournisseur"=>$fournisseur, "fournisseur2"=>$fournisseur2, "montant"=>$montant, "commentaire1"=>$commentaire1, "commentaire2"=>$commentaire2, "ordre_reglement"=>$ordre_reglement, "remboursement"=>$remboursement, "statut"=>$statut, "project"=>$project, "entry_by"=>$entry_by));
			if(data.ok=="ok") {
				$(myFormDiv+' #id_b').val(id_b);
				$(myFormDiv+' #entry_by').val(data.entry_by);
				$(myFormDiv+' #description').val(data.description);
				$(myFormDiv+' #date').val(data.date);
				$(myFormDiv+' #fournisseur').val(data.fournisseur);
				$(myFormDiv+' #fournisseur2').val(data.fournisseur2);
				$(myFormDiv+' #montant').val(data.montant);
				$(myFormDiv+' #commentaire1').val(data.commentaire1);
				$(myFormDiv+' #commentaire2').val(data.commentaire2);
				$(myFormDiv+' #ordre_reglement').val(data.ordre_reglement);
				$(myFormDiv+' #remboursement').val(data.remboursement);
				$(myFormDiv+' #pay_type').val(data.pay_type);
				$(myFormDiv+' #pay_date').val(data.pay_date);
				$(myFormDiv+' #statut').val(data.statut);
				$(myFormDiv+' #project').val(data.project);
				$(myFormDiv+' #uploaded').empty().append(data.snippet);
				if(globals.type!='user') {
					$(myFormDiv+' #commentaire2').prop('disabled',false);
					$(myFormDiv+' #statut').prop('disabled',false);
					$(myFormDiv+' #project').prop('disabled',false);
					$(myFormDiv+' #pay_type').prop('disabled',false);
					$(myFormDiv+' #pay_date').prop('disabled',false);
				}
			}
			else alert("Suite à un problème technique, je n'ai pas trouvé ce bordereau de dépense !");
		}, "json").always(function(){
			$(myFormModal).modal('show');
		});
	},
	delAttachedFile: function(id_b, filename, filefield, filepath, thisBtn, event) {
		event.preventDefault(); // prevents mod form submission !
		const confirmDeletion = confirm("Êtes-vous certain de vouloir supprimer ce document ?");
		if (confirmDeletion) {
			$(thisBtn).attr("disabled", true);
			$.post(globals.serverAddress, {id_b: id_b, filename: filename, filefield: filefield, filepath: filepath, id: globals.id, type: globals.type, pwd: globals.pwd, req: 'delAttachedFile'}, function(data){
				if(data.ok=="ok") {
					$(thisBtn).closest('li').remove();
					//alert('Document supprimé.');
					App.getBordereauxList();
				}
				else alert('Le document n\'a pas été supprimé.');
			}, "json").always(function(){
				$(thisBtn).attr("disabled", false);
			});
		}
	},
	modBordereaux: function(myFormDiv)
	{
		$(myFormDiv+' #sender').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse"></i>&nbsp;Veuillez patienter');
		let request = new FormData($(myFormDiv)[0]);
		const req = "modBordereaux";
		request.append("req", req); // req is included in form data here
		request.append("id", globals.id);
		request.append("pwd", globals.pwd);
		request.append("mail", globals.login);
		// We add disabled inputs vars manually to the POST...
		request.append("project",  $(myFormDiv+' #project').val());
		request.append("commentaire2", $(myFormDiv+' #commentaire2').val());
		request.append("statut",  $(myFormDiv+' #statut').val());
		request.append("pay_type",  $(myFormDiv+' #pay_type').val());
		request.append("pay_date",  $(myFormDiv+' #pay_date').val());
		let returns = "";
		$.ajax({
			url: globals.serverAddress,
			type: 'POST',
			data: request,
			dataType: "json",
			cache: false,
			contentType: false,
			processData: false
		}).done(function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b>Le bordereau a bien été mis à jour.</b></div>';
				App.getBordereauxList();
				setTimeout(function(){
					$(myFormDiv).closest('.modal').modal('hide');
					// App.clearFormFields(myFormDiv);
				}, 1600);
			}
			else
				returns = '<div class="alert alert-danger" role="alert"><b>Le bordereau n\'a pas été modifié suite à un problème technique.</b></div>';
		}, "json").always(function(){
			$(myFormDiv+' #sender').attr("disabled", false).html('<i class="fa fa-save"></i>&nbsp;Enregistrer');
			$(myFormDiv+' #successfail').empty().append(returns);
		});
	},
	addBordereaux: function(myFormDiv)
	{
		$(myFormDiv+' #sender').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse"></i>&nbsp;Veuillez patienter');
		// Serializing checkBoxes first for regular checkBoxes, second for Bootstrap's customs (assuming id=name)...
		//let checkBoxes=$(myFormDiv+" input[type='checkbox']").map(function(){return this.name+"="+this.checked;}).get().join("&");
		//let checkBoxes=$(myFormDiv+" input[type='checkbox']").map(function(){return this.id+"="+this.checked;}).get().join("&");
		//request = request + "&" + checkBoxes + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
		let request = new FormData($(myFormDiv)[0]);
		const req = "addBordereaux";
		const nom_adherent = globals.name+' '+globals.firstname;
		request.append("req", req); // req is included in form data here
		request.append("id", globals.id);
		request.append("pwd", globals.pwd);
		request.append("mail", globals.login);
		request.append("centers", globals.contract);
		request.append("nom_adherent", nom_adherent);
		let returns = "";
		$.ajax({
			url: globals.serverAddress,
			type: 'POST',
			data: request,
			dataType: "json",
			cache: false,
			contentType: false,
			processData: false
		}).done(function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b>Le bordereau a bien été ajouté.</b></div>';
				// if(window.location.hash.indexOf('bordereaux') !== -1) App.getBordereauxList(); // Only refresh table if it's on the page.
				if($.sessionStorage.getItem('myPage') == 'bordereaux') App.getBordereauxList(); // Only refresh table if it's on the page.
				setTimeout(function(){
					$(myFormDiv).closest('.modal').modal('hide');
					App.clearFormFields(myFormDiv);
				}, 1600);
			}
			else if (!data.checkFunds) returns = '<div class="alert alert-danger" role="alert"><b>Le bordereau n\'a pas été ajouté car vous ne disposez pas des fonds nécessaires.</b></div>';
			else returns = '<div class="alert alert-danger" role="alert"><b>Le bordereau n\'a pas été ajouté, suite à un problème technique.</b></div>';
		}, "json").always(function(){
			$(myFormDiv+' #sender').attr("disabled", false).html('<i class="fa fa-save"></i>&nbsp;Enregistrer');
			$(myFormDiv+' #successfail').empty().append(returns);
		});
	},
	delBordereaux: function(myFormDiv, event)
	{
		event.preventDefault(); // prevents mod form submission !
		const confirmDeletion = confirm("Êtes-vous certain de vouloir supprimer ce bordereau ?");
		if (confirmDeletion) {
			$(myFormDiv+' #deleter').attr("disabled", true);
			const delId = $(myFormDiv+' #id_b').val();
			const req = "delBordereaux";
			let query = "id_b=" + delId + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
			let returns = "";
			//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
			$.post(globals.serverAddress, query, function(data){
				if(data.ok=="ok") {
					returns = '<div class="alert alert-success" role="alert"><b>ce bordereau a bien été supprimée.</b></div>';
					App.getBordereauxList();
					setTimeout(function(){
						$(myFormDiv).closest('.modal').modal('hide');
						// App.clearFormFields(myFormDiv);
					}, 1600);
				}
				else
					returns = '<div class="alert alert-danger" role="alert"><b>ce bordereau n\'a pas été supprimée suite à un problème technique.</b></div>';
			}, "json").always(function(){
				$(myFormDiv+' #deleter').attr("disabled", false);
				$(myFormDiv+' #successfail').empty().append(returns);
			});
		}
	},
	printBordereaux: function(id_b, thisBtn)
	{
		$(thisBtn).attr("disabled", true);
		const req = "printBordereaux";
		let query = "id_b=" + id_b + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
		$.post(globals.serverAddress, query, function(data){
			if(data.ok=="ok") {
				const a = $("<a>").attr("href", data.pdflink).attr("download", data.pdfname).appendTo("footer");
				a[0].click();
				a.remove();
				// const link = document.createElement('a');
				// link.href = data.pdflink;
				// link.setAttribute('download', data.pdfname);
				// document.body.appendChild(link);
				// link.click();
			}
			else alert('Suite à un problème technique le fichier n\'a pas été créé !');
		}, "json").always(function(){
			$(thisBtn).attr("disabled", false);
		});
	},
	getPvAg: function() {
		const req = "getPvAg";
		const query = "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&req=" + req;
		$.post(globals.serverAddress, query, function(data){
			if(data.ok=="ok") {
				$("#pvAgCont").empty().append(data.snippet);
			}
			else alert("Impossible de charger la liste des PV d'assemblée générale suite à un problème technique.\r\nVeuillez recharger la page ou nous contacter si cela se reproduit.");
		}, "json");
	},
	setProfilePage: function() {
		$('#modProfileForm #auto_u').val(globals.id);
		$('#modProfileForm #wpid_u').val(globals.wpid);
		$('#modProfileForm #name_u').val(globals.name);
		$('#modProfileForm #firstname_u').val(globals.firstname);
		$('#modProfileForm #login_u').val(globals.email);
		$('#modProfileForm #mails_u').val(globals.mails);
		$('#modProfileForm #phone_u').val(globals.phone);
		$('#modProfileForm #phone2_u').val(globals.phone2);
		$('#modProfileForm #address_u').val(globals.address);
		$('#modProfileForm #address2_u').val(globals.address2);
		$('#modProfileForm #zip_u').val(globals.zip);
		$('#modProfileForm #city_u').val(globals.city);
		$('#modProfileForm #country_u').val(globals.country);
		// $('#modProfileForm #pwd_u').val();
		$('#modProfileForm #contract_u').val(globals.contract);
		$('#modProfileForm #quadra_u').val(globals.quadra);
		$('#modProfileForm #quadra_dis').val(globals.quadra);
		$('#modProfileForm #payment_u').val(globals.payment);
		if(globals.type!='user') $('#modProfileForm #quadra_u').prop('disabled',false);
		$.post(globals.serverAddress, {auto_u: globals.id, id: globals.id, type: globals.type, pwd: globals.pwd, req: 'getUsersRibsList'}, function(data){
			$("#userRibsListCont").empty().append(data.snippet);
		}, "json");
	},
	fillModProfile: function(auto_u, wpid_u, name_u, firstname_u, login_u, pwd_u, mails_u, phone_u, phone2_u, address_u, address2_u, zip_u, city_u, country_u, contract_u, quadra_u, payment_u, created_u, lastmod_u) {
		// App.clearFormFields('#modProfileForm');
		$('#modProfileFormPop #auto_u').val(auto_u);
		$('#modProfileFormPop #wpid_u').val(wpid_u);
		$('#modProfileFormPop #name_u').val(name_u);
		$('#modProfileFormPop #firstname_u').val(firstname_u);
		$('#modProfileFormPop #login_u').val(login_u);
		$('#modProfileFormPop #pwd_u').val(pwd_u);
		$('#modProfileFormPop #mails_u').val(mails_u);
		$('#modProfileFormPop #phone_u').val(phone_u);
		$('#modProfileFormPop #phone2_u').val(phone2_u);
		$('#modProfileFormPop #address_u').val(address_u);
		$('#modProfileFormPop #address2_u').val(address2_u);
		$('#modProfileFormPop #zip_u').val(zip_u);
		$('#modProfileFormPop #city_u').val(city_u);
		$('#modProfileFormPop #country_u').val(country_u);
		$('#modProfileFormPop #contract_u').val(contract_u);
		$('#modProfileFormPop #quadra_u').val(quadra_u);
		$('#modProfileFormPop #payment_u').val(payment_u);
		$('#modProfileFormPop #previouslyPayed').val(payment_u);
		$('#modProfileFormPop #created_u').val(created_u);
		$('#modProfileFormPop #lastmod_u').val(lastmod_u);
		// if(menage_p=='1') $('#modProfileFormPop #menage_p').prop("checked", true);
		// $('#modProfileFormPop #comments_p').val(comments_p.replace(/  /gi, "\r\n")); // Put back chariot's returns.
		$.post(globals.serverAddress, {auto_u: auto_u, id: globals.id, type: globals.type, pwd: globals.pwd, req: 'getUsersRibsList'}, function(data){
			$("#userRibsListCont").empty().append(data.snippet);
		}, "json").always(function() {
			$('#modProfileModal').modal('show');
		});
	},
	modProfile: function(myFormDiv)
	{
		$(myFormDiv+' #sender').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse"></i>&nbsp;Veuillez patienter');
		let query = $(myFormDiv).serialize();
		const req = "modProfile";
		// Serializing checkBoxes first for regular checkBoxes, second for Bootstrap's customs (assuming id=name actually works with both)...
		//let checkBoxes=$(myFormDiv+" input[type='checkbox']").map(function(){return this.name+"="+this.checked;}).get().join("&");
		//let checkBoxes=$(myFormDiv+" input[type='checkbox']").map(function(){return this.id+"="+this.checked;}).get().join("&");
		//query = query + "&" + checkBoxes + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
		// query = query + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&req=" + req;
		let request = new FormData($(myFormDiv)[0]);
		request.append("req", req); // req is included in form data here
		request.append("id", globals.id);
		request.append("pwd", globals.pwd);
		request.append("type", globals.type);
		let returns = "";
		$.ajax({
			url: globals.serverAddress,
			type: 'POST',
			data: request,
			dataType: "json",
			cache: false,
			contentType: false,
			processData: false
		}).done(function(data){
			if(data.ok=="ok") {
				if(myFormDiv=='#modProfileFormPop') {
					returns = '<div class="alert alert-success" role="alert"><b>Le profil a bien été mis à jour.</b></div>';
					App.setUsersListPage();
					setTimeout(function(){
						$(myFormDiv).closest('.modal').modal('hide');
						App.clearFormFields(myFormDiv);
					}, 1600);
				}
				if(myFormDiv=='#modProfileForm') {
					returns = '<div class="alert alert-success" role="alert"><b>Le profil a bien été mis à jour.<br>Attention: Vous allez devoir vous reconnecter !</b></div>';
					setTimeout(function(){
						App.logMeOut();
					}, 2600);
				}
			}
			else
				returns = '<div class="alert alert-danger" role="alert"><b>Le profil n\'a pas été modifié suite à un problème technique.</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' #sender').attr("disabled", false).html('<i class="fa fa-check-circle"></i>&nbsp;Enregistrer');
			$(myFormDiv+' #successfail').empty().append(returns);
		});
	},
	addProfile: function(myFormDiv)
	{
		$(myFormDiv+' #sender').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse"></i>&nbsp;Veuillez patienter');
		let query = $(myFormDiv).serialize();
		const req = "addProfile";
		// Serializing checkBoxes first for regular checkBoxes, second for Bootstrap's customs (assuming id=name)...
		//let checkBoxes=$(myFormDiv+" input[type='checkbox']").map(function(){return this.name+"="+this.checked;}).get().join("&");
		//let checkBoxes=$(myFormDiv+" input[type='checkbox']").map(function(){return this.id+"="+this.checked;}).get().join("&");
		// query = query + "&" + checkBoxes + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
		// query = query + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&req=" + req;
		let request = new FormData($(myFormDiv)[0]);
		request.append("req", req); // req is included in form data here
		request.append("id", globals.id);
		request.append("pwd", globals.pwd);
		request.append("type", globals.type);
		let returns = "";
		$.ajax({
			url: globals.serverAddress,
			type: 'POST',
			data: request,
			dataType: "json",
			cache: false,
			contentType: false,
			processData: false
		}).done(function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b>Le profil a bien été ajouté aux adhérents.</b></div>';
				App.setUsersListPage();
				setTimeout(function(){
					$(myFormDiv).closest('.modal').modal('hide');
					App.clearFormFields(myFormDiv);
				}, 1600);
			}
			else
				returns = '<div class="alert alert-danger" role="alert"><b>Le profil n\'a pas été ajouté aux adhérents, suite à un problème technique.</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' #sender').attr("disabled", false).html('<i class="fa fa-plus-circle"></i>&nbsp;Enregistrer');
			$(myFormDiv+' #successfail').empty().append(returns);
		});
	},
	delProfile: function(myFormDiv, event)
	{
		event.preventDefault(); // prevents mod form submission !
		const confirmDeletion = confirm("Êtes-vous certain de vouloir supprimer ce compte adhérent ?");
		if (confirmDeletion) {
			$(myFormDiv+' #deleter').attr("disabled", true);
			const delId = $(myFormDiv+' #auto_u').val();
			const WpId = $(myFormDiv+' #wpid_u').val();
			const req = "delProfile";
			let query = "auto_u=" + delId + "&wpid_u=" + WpId + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&req=" + req;
			let returns = "";
			//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
			$.post(globals.serverAddress, query, function(data){
				if(data.ok=="ok") {
					returns = '<div class="alert alert-success" role="alert"><b>Ce compte adhérent a bien été supprimée.</b></div>';
					App.setUsersListPage();
					setTimeout(function(){
						$(myFormDiv).closest('.modal').modal('hide');
						App.clearFormFields(myFormDiv);
					}, 1600);
				}
				else
					returns = '<div class="alert alert-danger" role="alert"><b>Ce compte adhérent n\'a pas été supprimée suite à un problème technique.</b></div>';
			}, "json").always(function(data){
				$(myFormDiv+' #deleter').attr("disabled", false);
				$(myFormDiv+' #successfail').empty().append(returns);
			});
		}
	},
	delRibs: function(delId, thisBtn, event) {
		event.preventDefault();
		const confirmDeletion = confirm("Êtes-vous certain de vouloir supprimer ce fichier ?");
		if (confirmDeletion) {
			$(thisBtn).attr("disabled", true).html('<i class="fa fa-spinner fa-pulse"></i>');
			const req = "delRibs";
			let query = "auto_r=" + delId + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&req=" + req;
			$.post(globals.serverAddress, query, function(data){
				if(data.ok=="ok") {
					$(thisBtn).closest('li').remove();
					// alert('Le fichier a bien été supprimé');
				}
				else
					alert('Le fichier n\'a pas été supprimé suite à un problème technique.');
			}, "json").always(function(data){
				$(thisBtn).attr("disabled", false).html('<i class="fa fa-2x fa-times mr-0"></i>');
			});
		}
	},
	setUsersListPage: function() {
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, req: 'getUsersList'}, function(data){
			$("#userListCont").empty().append(data.snippet);
		}, "json").done(function() {
			App.buildDataTables('#dataTable', 25, true, 0, 'desc', 'ADEREM Liste des Adhérents', [0,1,2,3,4,6,7,8,10], [5,9], '', '', '', [0,1,2,3,4,5,6,7,8,9,10]);
		});
	},
	setContractsPage: function() {
		this.getContractsList('C', 'Contrats', 'filterContractsList', 'setContractsPage');
		this.getRelatedFiles(globals.contract, 'C');
	},
	getContractsList: function(contractType, contractTypeName, filterFunction, setPageFunction, myFilter) {
		const contracts = globals.contract.replace(/\s/g, '').split(","); // strip spaces then make an array
		let isLikeAd = globals.type;
		isLikeAd = (contracts.length>10) ? 'MyBelovedLord' : isLikeAd;
		$("#contractsListCont").html('<div class="loading text-center"><h1 class="text-dark">Chargement...</h1><i class="fa fa-10x fa-spinner fa-pulse"></i></div>');
		$.post(globals.serverAddress, {id: globals.id, type: isLikeAd, pwd: globals.pwd, centers: globals.contract, contractType: contractType, myFilter: myFilter, req: 'getContractsList'}, function(data){
			$("#contractsListCont").empty().append(data.snippet);
			if(!myFilter) $("#filtersContAdmin").empty().append(data.filters);
		}, "json").done(function() {
			App.buildDataTables('#dataTable', 25, true, 3, 'desc', 'ADEREM Liste des '+contractTypeName, [1,3,4], [0,2]);
			App.refreshSmoothScroll();
		});
		// Creating Contracts Filters...
		if(globals.type=='user' && contracts.length<=10) {
			let filterBtns = '<b>Mes Budgets : </b>';
			contracts.forEach(function(item, index){
				filterBtns += '<button class="btn btn-primary btn-lg shadow" onclick="App.'+filterFunction+'(\''+item+'\');" title="Budget '+item+'"><i class="fa fa-filter"></i>&nbsp;'+item+'</button>&nbsp;';
			});
			filterBtns += '<button class="btn btn-warning btn-icon shadow" onclick="App.'+setPageFunction+'();" title="Rafraîchir / Effacer le filtre"><i class="fa fa-2x fa-sync"></i></button>';
			$("#filtersCont").empty().append(filterBtns);
		}
	},
	filterContractsList: function(myFilter) {
		this.getContractsList('C', 'Contrats', 'filterContractsList', 'setContractsPage', myFilter);
		App.getRelatedFiles(myFilter, 'C');
		// $('#dataTable tr.filters th[data-column-index=0] input').val(myFilter).trigger('change');
		// $('#dataTable tr.filters th').first().find('input').val(myFilter).trigger('change');
		// App.buildDataTables('#dataTable', 25, true, 3, 'desc', 'ADEREM Liste des Contrats', 'Code', 'starts', myFilter);
	},
	getRelatedFiles: function(contractList, contractType) {
		const contracts = contractList.replace(/\s/g, '').split(","); // strip spaces then make an array
		let isLikeAd = globals.type;
		isLikeAd = (contracts.length>10) ? 'MyBelovedLord' : isLikeAd;
		$.post(globals.serverAddress, {id: globals.id, type: isLikeAd, pwd: globals.pwd, centers: contractList, contractType: contractType, req: 'getRelatedFiles'}, function(data){
			$("#filesListCont").empty().append(data.snippet);
		}, "json");
		/*
		// Creating Contracts Filters...
		if(globals.type=='user' && contracts.length<=10) {
			let filterBtns = '<b>Mes Budgets : </b>';
			contracts.forEach(function(item, index){
				filterBtns += '<button class="btn btn-primary btn-lg shadow" onclick="App.filterContractsList(\''+item+'\');" title="Budget '+item+'"><i class="fa fa-filter"></i>&nbsp;'+item+'</button>&nbsp;';
			});
			filterBtns += '<button class="btn btn-warning btn-icon shadow" onclick="App.setContractsPage();" title="Rafraîchir / Effacer le filtre"><i class="fa fa-2x fa-sync"></i></button>';
			$("#filtersCont").empty().append(filterBtns);
		}
		*/
	},
	setGiftsPage: function() {
		App.getContractsList('D', 'Demandes de Dons', 'filterGiftsList', 'setGiftsPage');
		App.getRelatedFiles(globals.contract, 'D');
	},
	filterGiftsList: function(myFilter) {
		App.getContractsList('D', 'Demandes de Dons', 'filterGiftsList', 'setGiftsPage', myFilter);
		App.getRelatedFiles(myFilter, 'D');
		// $('#dataTable tr.filters th[data-column-index=0] input').val(myFilter).trigger('change');
		// App.buildDataTables('#dataTable', 25, true, 3, 'desc', 'ADEREM Liste des Demandes de Dons', 'Code', 'starts', myFilter);
	},
	setAuditsPage: function() {
		App.getContractsList('A', 'Audits', 'filterAuditsList', 'setAuditsPage');
		App.getRelatedFiles(globals.contract, 'A');
	},
	filterAuditsList: function(myFilter) {
		App.getContractsList('A', 'Audits', 'filterAuditsList', 'setAuditsPage', myFilter);
		App.getRelatedFiles(myFilter, 'A');
		// $('#dataTable tr.filters th[data-column-index=0] input').val(myFilter).trigger('change');
		// App.buildDataTables('#dataTable', 25, true, 3, 'desc', 'ADEREM Liste des Audits', 'Code', 'starts', myFilter);
	},
	setEventsPage: function() {
		App.getContractsList('E', 'Evènements', 'filterEventsList', 'setEventsPage');
		App.getRelatedFiles(globals.contract, 'E');
	},
	filterEventsList: function(myFilter) {
		App.getContractsList('E', 'Evènements', 'filterEventsList', 'setEventsPage', myFilter);
		App.getRelatedFiles(myFilter, 'E');
		// $('#dataTable tr.filters th[data-column-index=0] input').val(myFilter).trigger('change');
		// App.buildDataTables('#dataTable', 25, true, 3, 'desc', 'ADEREM Liste des Evènements', 'Code', 'starts', myFilter);
	},
	setStandsPage: function() {
		App.getContractsList('S', 'Stands', 'filterStandsList', 'setStandsPage');
		App.getRelatedFiles(globals.contract, 'S');
	},
	filterStandsList: function(myFilter) {
		App.getContractsList('S', 'Stands', 'filterStandsList', 'setStandsPage', myFilter);
		App.getRelatedFiles(myFilter, 'S');
		// $('#dataTable tr.filters th[data-column-index=0] input').val(myFilter).trigger('change');
		// App.buildDataTables('#dataTable', 25, true, 3, 'desc', 'ADEREM Liste des Stands', 'Code', 'starts', myFilter);
	},
	setFilesListPage: function() {
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, req: 'getFilesList'}, function(data){
			$("#filesListCont").html(data.snippet);
			$('#addFilesForm [contract-select-cont]').html(data.contractList);
		}, "json").done(function() {
			const filter = App.urlParam('filter', document.URL);
			const val = App.urlParam('val', document.URL);
			if(filter) App.buildDataTables('#dataTableFiles', 25, true, 0, 'asc', 'ADEREM Liste des Fichiers', [1,2,3], [0], filter, '=', val, [0,1,2,3]);
			else App.buildDataTables('#dataTableFiles', 25, true, 0, 'asc', 'ADEREM Liste des Fichiers', [1,2,3], [0], '', '', '', [0,1,2,3]);
		});
		this.buildUploadZone()
	},
	delFile: function(idFile, filename, filepath, thisBtn, event) {
		if(event) event.preventDefault(); // prevents mod form submission !
		const confirmDeletion = confirm("Êtes-vous certain de vouloir supprimer ce document ?");
		if (confirmDeletion) {
			const thisBtnHtml = $(thisBtn).html();
			$(thisBtn).attr("disabled", true).html('<i class="fa fa-2x fa-spinner fa-pulse"></i>');
			$.post(globals.serverAddress, {id_f: idFile, filename: filename, filepath: filepath, id: globals.id, type: globals.type, pwd: globals.pwd, req: 'delFile'}, function(data){
				if(data.ok=="ok") {
					alert('Document supprimé.');
					App.setFilesListPage();
				}
				else alert('Le document n\'a pas été supprimé.');
			}, "json").always(function(){
				$(thisBtn).attr("disabled", false).html(thisBtnHtml);
			});
		}
	},
	addFiles: function(myFormDiv) {
		$(myFormDiv+' #senderFiles').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse"></i>&nbsp;Veuillez patienter');
		let request = new FormData(document.querySelector(myFormDiv));
		const req = "addFiles";
		request.append("req", req); // req is included in form data here
		request.append("id", globals.id);
		request.append("pwd", globals.pwd);
		let returns = "";
		$.ajax({
			url: globals.serverAddress,
			type: 'POST',
			data: request,
			dataType: "json",
			cache: false,
			contentType: false,
			processData: false
		}).done(function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b>Les fichiers ont bien été ajoutés.</b></div>';
				App.setFilesListPage();
				setTimeout(function(){
					$(myFormDiv).closest('.modal').modal('hide');
					App.clearFormFields(myFormDiv);
				}, 1600);
			}
			else
				returns = '<div class="alert alert-danger" role="alert"><b>Les fichiers n\'ont pas été ajoutés, suite à un problème technique.</b></div>';
		}, "json").always(function(){
			$(myFormDiv+' #senderFiles').attr("disabled", false).html('<i class="fa fa-file-upload mr-1"></i>Envoyer');
			$(myFormDiv+' #successfail').empty().append(returns);
		});
	},
	fillModFiles: function(id_f, desc_f, filename, dirname, myFormDiv, myFormModal) {
		App.clearFormFields(myFormDiv);
		$(myFormDiv+' #id_f').val(id_f);
		$(myFormDiv+' #filename').val(filename);
		$(myFormDiv+' #dirname').val(dirname);
		const contract = dirname.split('/')[2];
		$(myFormDiv+' [data-file-mod-path]').html('<i class="fa fa-file-invoice mr-1"></i>'+contract+'<i class="fa fa-arrow-circle-right mx-1"></i>'+filename)
		$(myFormDiv+' #desc_f').val(desc_f.replaceAll('<br>', '\r\n'));
		$(myFormModal).modal('show');
	},
	modFiles: function(myFormDiv) {
		const myBtn = $(myFormDiv+' [type=submit]')
		const myBtnHtml = $(myFormDiv+' [type=submit]').html()
		myBtn.attr("disabled", true).html('<i class="fa fa-spinner fa-pulse"></i>&nbsp;Veuillez patienter');
		let request = new FormData(document.querySelector(myFormDiv));
		const req = "modFiles";
		request.append("req", req); // req is included in form data here
		request.append("id", globals.id);
		request.append("pwd", globals.pwd);
		let returns = "";
		$.ajax({
			url: globals.serverAddress,
			type: 'POST',
			data: request,
			dataType: "json",
			cache: false,
			contentType: false,
			processData: false
		}).done(function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b>La déscription du fichier a bien été modifiée.</b></div>';
				App.setFilesListPage();
				setTimeout(function(){
					$(myFormDiv).closest('.modal').modal('hide');
					App.clearFormFields(myFormDiv);
				}, 1600);
			}
			else returns = '<div class="alert alert-danger" role="alert"><b>La déscription du fichier n\'a pas été modifiée, suite à un problème technique.</b></div>';
		}, "json").always(function(){
			myBtn.attr("disabled", false).html(myBtnHtml);
			$(myFormDiv+' #successfail').empty().append(returns);
		});
	},
	setAnalyticsCaLabsPage: function() {
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, centers: globals.contract, req: 'getAnalyticsCaLabs'}, function(data){
			$("[data-analytics-cont]").html(data.snippet);
			// $('#addFilesForm [contract-select-cont]').html(data.contractList);
		}, "json").done(function() {
			App.buildDataTables('#dataTable', 25, true, 0, null, 'ADEREM Analytique CA par laboratoires', [0,1,5], [2,3,4], '', '', '', [0,1,2,3,4,5]);
		});
	},
	setAnalyticsDonsPage: function() {
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, centers: globals.contract, req: 'getAnalyticsDons'}, function(data){
			$("[data-analytics-cont]").html(data.snippet);
			// $('#addFilesForm [contract-select-cont]').html(data.contractList);
		}, "json").done(function() {
			App.buildDataTables('#dataTable', 25, true, 1, 'desc', 'ADEREM Analytique Dons', [0,1,4,5], [2,3], '', '', '', [0,1,2,3,4,5]);
		});
	},
	setAnalyticsRecettesPage: function() {
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, centers: globals.contract, req: 'getAnalyticsRecettes'}, function(data){
			$("[data-analytics-cont]").html(data.snippet);
			// $('#addFilesForm [contract-select-cont]').html(data.contractList);
		}, "json").done(function() {
			App.buildDataTables('#dataTable', 25, true, 6, 'desc', 'ADEREM Analytique Recettes', [2,3,5,6], [0,1,4], '', '', '', [0,1,2,3,4,5,6]);
		});
	},
	setAnalyticsSoldeAffairesPage: function() {
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, centers: globals.contract, req: 'getAnalyticsSoldeAffaires'}, function(data){
			$("[data-analytics-cont]").html(data.snippet);
			// $('#addFilesForm [contract-select-cont]').html(data.contractList);
		}, "json").done(function() {
			App.buildDataTables('#dataTable', 25, true, 0, 'asc', 'ADEREM Analytique Solde Affaires', [5,6,7,8,9], [0,1,2,3,4], '', '', '', [0,1,2,3,4,5,6,7,8,9]);
		});
	},
	setAnalyticsDepensesPage: function() {
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, centers: globals.contract, req: 'getAnalyticsDepenses'}, function(data){
			$("[data-analytics-cont]").html(data.snippet);
			// $('#addFilesForm [contract-select-cont]').html(data.contractList);
		}, "json").done(function() {
			App.buildDataTables('#dataTable', 25, true, 6, 'desc', 'ADEREM Analytique Dépenses', [2,3,5,6], [0,1,4], '', '', '', [0,1,2,3,4,5,6]);
		});
	},
	buildUploadZone: () => { // Dealing with upload buttons and inputs logics
		document.querySelectorAll('[data-upload-btn]').forEach((element) => {
			const uploadInput = element.nextElementSibling // data-upload-input
			element.addEventListener('click', () => {
				uploadInput.click()
			})
			uploadInput.addEventListener('change', (event) => {
				const filesArray = event.target.files;
				const fileListCont = event.target.nextElementSibling // data-upload-list
				let prevFileList = '<ul class="mb-0">'
				fileListCont.querySelectorAll('[data-upload-list-item]').forEach((item) => {
					prevFileList += item.outerHTML
				})
				prevFileList += '</ul>'
				// const prevFileList = fileListCont.innerHTML
				fileListCont.classList.add('alert', 'alert-primary', 'my-1')
				let fileList = '<ul class="mb-0">';
				for(const file of filesArray) {
					fileList += `<li>${file.name} - ${App.calculateFileSize(file.size)}</li>`
				}
				fileList += '</ul>'
				fileListCont.innerHTML = prevFileList+fileList;
				// 0: File { name: "Liste des Contacts.pdf", lastModified: 1697026886175, size: 18212, type: "application/pdf", … }
				// 1: File { name: "moi.jpg", lastModified: 1696279543911, size: 416942, type: "image/jpeg", … }
			});
		})
	},
	calculateFileSize: (fileSize) => {
		let numberOfBytes = fileSize
		// Calculate total size
		// let numberOfBytes = 0;
		// for (const file of uploadInput.files) {
		// 	numberOfBytes += file.size;
		// }
		// Approximate to the closest prefixed unit
		const units = [
			"B",
			"KB",
			"MB",
			"GB",
			"TB",
			"PB",
			"EB",
			"ZB",
			"YB",
		];
		const exponent = Math.min(
			Math.floor(Math.log(numberOfBytes) / Math.log(1024)),
			units.length - 1,
		);
		const approx = numberOfBytes / 1024 ** exponent;
		const output = (exponent === 0) ? `${numberOfBytes} octets` : `${new Intl.NumberFormat().format(approx.toFixed(3))} ${units[exponent]}`;
		// const output = (exponent === 0) ? `${numberOfBytes} bytes` : `${approx.toFixed(3)} ${units[exponent]} (${numberOfBytes} bytes)`;
		// console.log(new Intl.NumberFormat('fr-FR', { maximumSignificantDigits: 3 }).format(approx));
		return output;
	},
	setCotisationsPage: function() {
		if(globals.type=='MyBelovedLord') {
			$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, req: 'getCotisationsList'}, function(data){
				$("#cotisationsListCont").empty().append(data.snippet);
			}, "json").done(function() {
				App.buildDataTables('#dataTable', 25, true, 3, 'asc', 'ADEREM Liste des Cotisations', [0,2,3,4,5], [1,6]);
			});
		}
		else {
			// $('#cotisationFrame').attr('src', 'https://www.aderem.fr/bo/pay/paypage.php?auto_u='+globals.id);
			if(globals.isMobile) $('#cotisationFrame').closest('.embed-responsive').removeClass('embed-responsive-16by9').addClass('embed-responsive-1by1');
			if($('#cotisationFrame').length) document.getElementById('cotisationFrame').src += '?auto_u='+globals.id;
			App.getCotisationsFiles();
		}
	},
	popSomeonesPaymentPage(auto_u) {
		$('#cotisationFramePop').attr('src', 'https://www.aderem.fr/bo/pay/paypage.php?auto_u='+auto_u);
		$('#cotisationModal').modal('show');
	},
	getCotisationsFiles: function() {
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, quadra: globals.quadra, req: 'getCotisationsFiles'}, function(data){
			$("#cotisationsDocsCont").empty().append(data.snippet);
		}, "json");
	},
	setPaymentListPage: function() {
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, req: 'getPaymentsList'}, function(data){
			$("#paymentsListCont").empty().append(data.snippet);
		}, "json").done(function() {
			App.buildDataTables('#dataTable', 25, true, 0, 'desc', 'ADEREM journal des Paiements', [0,1,2,3,4,5,6,7,8], [9]);
		});
	},
	setCongresListPage: function() {
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, req: 'getCongresList'}, function(data){
			$("#congresListCont").empty().append(data.snippet);
		}, "json").done(function() {
			App.buildDataTables('#dataTable', 25, true, 0, 'desc', 'ADEREM journal des Inscriptions', [0,1,2,4,6,7,8], [3,5,9]);
		});
	},
	buildMyDropzone: function() {
		Dropzone.options.myDropzone = {
			//$("#my-dropzone").dropzone({
			url: globals.serverAddress,
			paramName: "files_bord", // name of file input (defaults to file) here with uploadMultiple it will be files_bord[]
			autoProcessQueue: false,
			uploadMultiple: true,
			parallelUploads: 10,
			maxFiles: 10,
			maxFilesize: 12,
			// acceptedFiles: "image/*,application/pdf",
			addRemoveLinks: true,
			dictFileTooBig: "Ce fichier est trop volumineux !",
			dictMaxFilesExceeded: "Nous limitons l'envoi à 10 documents",
			dictInvalidFileType: "Type de fichier non accepté, images ou pdf seulement.",
			dictCancelUpload: "Annuler",
			dictUploadCanceled: "Annulé",
			dictCancelUploadConfirmation: "Voulez-vous vraiment annuler ?",
			dictRemoveFile: "<i class='fa fa-times-circle'></i> Effacer",
			dictRemoveFileConfirmation: "Voulez-vous vraiment effacer ?",
			dictResponseError: "Suite à une erreur technique votre demande n'a pas été traitée.",
			headers: {
				'Cache-Control': null,
				'X-Requested-With': null,
			},
			init: function () {
				var wrapperThis = this;
				var errorMeOnce = '';

				$('#addBordereauxForm #sender').on("click", function (e) {
					e.preventDefault();
					e.stopPropagation();
					var validForm = $('#addBordereauxForm')[0].checkValidity();
					// var validDescription = document.getElementById("description").checkValidity();
					if (!validForm) {
						// Here you can handle the empty input case, color the inputs red or whatever
						$('#addBordereauxForm #successfail').empty().append('<div class="alert alert-danger" role="alert"><b>Veuillez vérifier les champs entourés en rouge ci-dessus</b></div>');
						return false;

					} else {
						if(!wrapperThis.getAcceptedFiles().length>0) {
							App.addBordereaux('#addBordereauxForm'); // If dropzone is empty we make the call without it !
							// alert("Veuillez sélectionner au moins un document à transférer"); return false;
						}
						else { // All form is property filled and at least one file is to be uploaded...
							$('#addBordereauxForm #sender').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse"></i>&nbsp;Veuillez patienter');
							$('#my-dropzone').fadeTo('slow',.6);
							$('#my-dropzone').append('<div id="disablingDrozone" style="position: absolute;top:0;left:0;width: 100%;height:100%;z-index:2;opacity:0.4;filter: alpha(opacity = 50)"></div>');
							wrapperThis.processQueue();
						}
					}
				});
				/*
				this.on("addedfile", function (file) {
					// Create the remove button
					var removeButton = Dropzone.createElement("<button class='btn btn-danger btn-sm'><i class='fa fa-times-circle'></i> Effacer</button>");
					// Listen to the click event
					removeButton.addEventListener("click", function (e) {
						// Make sure the button click doesn't submit the form:
						e.preventDefault();
						e.stopPropagation();
						// Remove the file preview.
						wrapperThis.removeFile(file);
						// If you want to the delete the file on the server as well,
						// you can do the AJAX request here.
					});
					// Add the button to the file preview element.
					file.previewElement.appendChild(removeButton);
				});
				*/
				this.on('sendingmultiple', function (data, xhr, formData) {
					$('#addBordereauxForm #progressCont').empty().append('<div class="progress mb-4" style="height: 2rem;"><div class="progress-bar bg-warning progress-bar-striped progress-bar-animated" aria-valuenow="1" aria-valuemin="0" aria-valuemax="100" style="width: 1%;"><span class="sr-only">1%</span></div></div>');
					//let request = new FormData($("#addBordereauxForm")[0]);
					//request.append("req", "addBordereaux");
					const nom_adherent = globals.name+' '+globals.firstname;
					formData.append("req", "addBordereaux");
					formData.append("id", globals.id);
					formData.append("pwd", globals.pwd);
					formData.append("mail", globals.login);
					formData.append("nom_adherent", nom_adherent);
					$(":input[name]", $("#addBordereauxForm")).each(function () {
						formData.append(this.name, $(':input[name=' + this.name + ']', $("#addBordereauxForm")).val());
					});
					//formData.append("Username", $("#Username").val());
				});
				this.on('uploadprogress', function (file, progress, bytesSent) {
					$('#addBordereauxForm #progressCont').empty().append('<div class="progress my-4" style="height: 2rem;"><div class="progress-bar bg-primary progress-bar-striped progress-bar-animated" aria-valuenow="'+progress+'" aria-valuemin="0" aria-valuemax="100" style="width: '+progress+'%;"><span class="sr-only">'+progress+'%</span></div></div>');
				});
				this.on('error', function (file, errorMessage, xhr) {
					if(errorMeOnce.indexOf(file.name) === -1) $('#addBordereauxForm #successfail').append('<div class="alert alert-danger" role="alert"><b>Le fichier '+file.name+' n\'a pas été envoyé.<br>Erreur: '+errorMessage+'.</b></div>');
					errorMeOnce += file.name;
					console.warn('Erreur pour le fichier '+file.name+' : '+errorMessage+' / '+xhr);
					$('#addBordereauxForm #progressCont').empty();
					wrapperThis.removeAllFiles();
				});
				this.on('successmultiple', function (file, response) {
					let jsonResponse = JSON.parse(response);
					if(jsonResponse.ok == "ok") {
						$('#addBordereauxForm #successfail').empty().append('<div class="alert alert-success" role="alert"><b>Le bordereau a bien été ajouté.</b></div>');
						// if(window.location.hash.indexOf('bordereaux') !== -1) App.getBordereauxList(); // Only refresh table if it's on the page.
						if($.sessionStorage.getItem('myPage') == 'bordereaux') App.getBordereauxList(); // Only refresh table if it's on the page.
						setTimeout(function(){
							$("#addBordereauxForm").closest('.modal').modal('hide');
							App.clearFormFields("#addBordereauxForm");
							wrapperThis.removeAllFiles();
						}, 3000);
					}
					else {
						$('#addBordereauxForm #successfail').empty().append('<div class="alert alert-danger" role="alert"><b>Le bordereau n\'a pas été ajouté, suite à un problème technique.</b></div>');
						wrapperThis.removeAllFiles();
					}
					//alert('Success: '+ jsonResponse);
				});
				this.on('completemultiple', function (file, jsonResponse) {
					//It is like jQuery always
					$('#addBordereauxForm #sender').attr("disabled", false).html('<i class="fa fa-save"></i>&nbsp;Enregistrer');
					$('#my-dropzone').fadeTo('slow',1);
					$('#disablingDrozone').remove();
					$('#addBordereauxForm #progressCont').empty();
				});
				/*
				// To access only accepted files count (answer of question)
				this.getAcceptedFiles().length
				// To access all files count
				this.files.length
				// To access all rejected files count
				this.getRejectedFiles().length
				// To access all queued files count
				this.getQueuedFiles().length
				// To access all uploading files count
				this.getUploadingFiles().length
				*/
			}
		//});
		};
		//var myDropzone = new Dropzone("#my-dropzone", myDropzoneOptions);
		Dropzone.discover();
	},
};

// Expose App object to window object => need this to use click event in html
window.App = App;
if($.localStorage.getItem('type')!='aphm') require('./router.js');

(function() {
	// If not logged in and page is not login redirects to login page...
	if ($.localStorage.getItem('pass')!='OK' && document.URL.indexOf( 'login.html' ) === -1)
	{
		document.location.href='login.html';
	}
	else if (document.URL.indexOf( 'login.html' ) !== -1) {
		// It's login page.
		App.init();
	}
	else {
		// pass ok...
		App.init();
		// App.checkAccountStatus();
	}
})();